import "sweetalert2/dist/sweetalert2.min.css";
import "vue2-animate/dist/vue2-animate.min.css";
import {shallowRef} from "vue";
import {SweetAlertOptions} from "sweetalert2";
import Swal from "sweetalert2/dist/sweetalert2.min";
import modalParams, {SfModalParams} from "@songfinch/shared/plugins/modal/modalParams";

const modal = {
    swal: undefined,
    sfModal: async function (payload: SfModalParams) {
        if (payload?.component) {
            let component = payload.component;
            if (typeof payload?.component === "function") {
                component = (await payload.component()).default;
            }
            payload.component = shallowRef(component);
        }
        setTimeout(() => modalParams.value = payload);
    }
};

export function swalInit(options: SweetAlertOptions = {}) {
    const swalLocalInstance = Swal.mixin(options);
    const swalFunction = function (...args) {
        return swalLocalInstance.fire.call(swalLocalInstance, ...args);
    };
    Object.assign(swalFunction, Swal);
    Object.keys(Swal)
        .filter(key => typeof Swal[key] === "function")
        .forEach(methodName => {
            swalFunction[methodName] = swalLocalInstance[methodName].bind(swalLocalInstance);
        });
    modal.swal = swalFunction;
}

export default modal;
