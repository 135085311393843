import {App, Plugin} from "vue";
import {cloneDeep} from "lodash-es";
import {SegmentConfig} from "@songfinch/data-source/configs/index";

function initSegment(config: SegmentConfig) {
    const i = "analytics", analytics = window[i] = window[i] || [];
    if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
        analytics.invoked = !0;
        analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "screen", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware", "register"];
        analytics.factory = function (e) {
            return function () {
                // eslint-disable-next-line prefer-spread,prefer-rest-params
                if (window[i].initialized) return window[i][e].apply(window[i], arguments);
                // eslint-disable-next-line prefer-rest-params
                const n = Array.prototype.slice.call(arguments);
                if (["track", "screen", "alias", "group", "page", "identify"].indexOf(e) > -1) {
                    const c = document.querySelector("link[rel='canonical']");
                    n.push({
                        __t: "bpc",
                        c: c && c.getAttribute("href") || void 0,
                        p: location.pathname,
                        u: location.href,
                        s: location.search,
                        t: document.title,
                        r: document.referrer
                    });
                }
                n.unshift(e);
                analytics.push(n);
                return analytics;
            };
        };
        for (let n = 0; n < analytics.methods.length; n++) {
            const key = analytics.methods[n];
            analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, n) {
            const t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.setAttribute("data-global-segment-analytics-key", i);
            t.src = config.jsSrc;
            const r = document.getElementsByTagName("script")[0];
            r.parentNode.insertBefore(t, r);
            analytics._loadOptions = n;
        };
        analytics._writeKey = config.writeKey;
        analytics._cdn = config.cdn;
        analytics.SNIPPET_VERSION = "5.2.0";
        analytics.load(config.writeKey);
        analytics.page();

        setMessageIdOverwrite(analytics);
    }
}

function setMessageIdOverwrite(analytics) {
    const eventsToOverwrite = ["Song Purchased", "Order Completed", "Item Purchased"];

    analytics.addSourceMiddleware(({payload, next}) => {
        const event = payload.obj;
        if (eventsToOverwrite.includes(event.event) && event.context.event_id) {
            event.messageId = event.context.event_id;
        }
        next(payload);
    });
}

function initGTM(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        "gtm.start":
            new Date().getTime(), event: "gtm.js"
    });
    const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src =
        "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $customEvent: (event: string, details?: unknown) => void
    }
}

export const customEvent = (event, details?) => {
    const segmentConf = window.appSettings?.segment;
    if (!window.analytics && segmentConf?.writeKey && segmentConf?.jsSrc && segmentConf?.cdn) initSegment(window.appSettings.segment);
    if (!window.dataLayer && window.appSettings?.gtmID) initGTM(window, document, "script", "dataLayer", window.appSettings.gtmID);

    document.dispatchEvent(new CustomEvent(event, {
        detail: cloneDeep(details)
    }));
};

const sfAnalytics: Plugin = {
    install(app: App) {
        app.config.globalProperties.$customEvent = customEvent;
        app.provide("$customEvent", customEvent);

        app.config.globalProperties.$analyticPageViewedTrigger = () => {
            customEvent("_sf_pageview", {
                request_id: app.config.globalProperties.$store?.state.core_cms.__headers?.["x-request-id"],
            });
        };
    }
};


export default sfAnalytics;
