import dayjs from "dayjs";

import {App, Plugin} from "vue";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);
declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $dayjs: dayjs;
        $defaultImage: "static/image_placeholder.png";
        $sfPriceInDollars: (
            price: number | string,
            details?: Intl.NumberFormatOptions
        ) => string;
    }
}

const sfHelper: Plugin = {
    install(app: App) {
        app.config.globalProperties.$dayjs = dayjs;
        app.config.globalProperties.$defaultImage = "static/image_placeholder.png";
        app.config.globalProperties.$sfPriceInDollars = (
            price,
            options = {}
        ) => {
            try {
                return new Intl.NumberFormat("en-EN", {
                    style: "currency",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    currency: "USD",
                    ...options,
                }).format(price / 100);
            } catch (e) {
                return "Invalid format";
            }
        };
    },
};

export default sfHelper;
