import modal, {swalInit} from "./index";
import {SweetAlertOptions} from "sweetalert2";
import {App} from "vue";

export default {
    install(app: App, options: SweetAlertOptions) {
        swalInit(options);
        app.config.globalProperties.$swal = modal.swal;
        app.provide("$swal", modal.swal);
    }
};
